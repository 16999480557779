export default {
    "Module": {
        "Home": "Hogar",
        "PerpetualContract": "Futuros perpetuos",
        "CurrencyTrading": "Transacción de moneda",
        "SecondContract": "Contrato de opciones",
        "Finance": "Finanzas",
		"OurTeam": "Nuestro equipo",
        "Announcement": "Anuncio",
        "Assets": "Activos",
        "Account": "Cuenta"
    },
    "Login": {
        "Login": "Acceso",
        "Register": "Registro",
        "RetrievePassword": "Recuperar Contraseña",
        "Type": "Correo electrónico",
        "Email": "Correo electrónico",
        "Password": "Contraseña",
        "ConfirmPassword": "confirmar Contraseña",
        "VerificationCode": "Código de verificación",
        "Send": "Enviar",
        "PhoneNumber": "Número de teléfono",
        "InvitationCode": "Código de invitación",
        "Agree": "He leído y estoy de acuerdo",
        "UserAgreement": "Acuerdo del Usuario",
        "EnterEmail": "Por favor introduzca su correo electrónico",
        "EnterEmailError": "Ingrese el formato de correo electrónico correcto",
        "EnterPassword": "Por favor, introduzca su contraseña",
        "EnterPasswordError": "El formato de la contraseña es de 6 a 20 caracteres",
        "EnterConfirmPassword": "Por favor, confirme su contraseña",
        "EnterConfirmPasswordError": "Las contraseñas ingresadas dos veces son inconsistentes",
        "EnterEmailFirst": "Por favor ingrese su correo electrónico primero",
        "EnterVerificationCode": "por favor ingrese el código de verificación",
        "EnterPhoneNumber": "Por favor, introduzca su número de teléfono móvil",
        "EnterInvitationCode": "Por favor, introduzca el código de invitación",
        "ConfirmAgreement": "Lea y acepte el Acuerdo de usuario",
        "Forget": "¿Olvidaste la contraseña?"
    },
    "Home": {
        "TcTitle": "Notificación",
        "TopTitle1": "Inicie su inversión en criptomonedas rápidamente",
        "TopTitle2": "NEODEAL es el lugar más confiable y seguro para comprar y vender criptomonedas",
        "EmailAddress": "Dirección de correo electrónico",
        "StartUsing": "Empieza a usar",
        "SeeMore": "Ver más",
        "MarketTrend": "Tendencia del mercado",
        "Currency": "Divisa",
        "LatestPrice": "Último precio",
        "Change": "cambio de 24H",
        "TradingVolume": "Volumen de negociación de 24H",
        "Trend": "Tendencia",
        "Operate": "Funcionar",
        "Trade": "Comercio",
        "AllTradingVolume": "Intercambio de volumen de operaciones de 24H",
        "SupportedCountries": "Países admitidos",
        "RegisterNumber": "Número de usuarios registrados",
        "Box4Title": "La plataforma de criptomonedas más confiable",
        "Box4Desc": "Estas son las razones por las que deberías elegir NEODEAL",
        "Box4TabTitles": [
            "Transacciones diversificadas",
            "Tarifa mínima",
            "Seguridad líder",
            "Experiencia de usuario definitiva"
        ],
        "Box4TabDescs": [
            "NEODEAL proporciona una cartera de pedidos con la máxima liquidez, comercio rico en criptomonedas y servicios de gestión de activos para satisfacer diversas necesidades comerciales.",
            "Al proporcionar a los comerciantes acceso al mercado de financiamiento entre pares, NEODEAL elige pares específicos de comercio al contado de criptomonedas con las tarifas de transacción más bajas.",
            "NEODEAL adopta un método de almacenamiento fuera de línea, y los datos de transacciones personales están garantizados a través del cifrado de extremo a extremo, y la plataforma monitorea en tiempo real para proteger completamente la seguridad de los activos de los usuarios.",
            "Primero el usuario, fácil de usar, escuchando comentarios, optimización rápida del producto, 7*24 horas en tiempo real en línea, servicios profesionales uno a uno multilingües."
        ],
        "Box5Title": "Líneas de productos enriquecidas constantemente para satisfacer las necesidades de varios usuarios.",
        "Box5TabTitles": [
            "Futuros perpetuos",
            "Transacción de moneda",
            "Contrato de opciones",
            "Finanzas"
        ],
        "Box5TabDescs": [
            "Apalancar el comercio, utilizando el apalancamiento para usar sus propios fondos como margen, pedir prestado N veces los fondos de la plataforma como principal y realizar inversiones para obtener ganancias.",
            "Use una determinada moneda digital como unidad de precio, compre otros tipos de moneda digital, compre y venda rápidamente criptomonedas al mejor precio y obtenga los mejores rendimientos.",
            "Derivados financieros, mediante el análisis de las condiciones del mercado a corto plazo, los comerciantes compran o venden a un precio predeterminado en un momento específico y comercian con una frecuencia rápida para obtener ganancias.",
            "Alta tasa de rendimiento anualizada competitiva, garantía de seguridad de capital de primera clase, ¡fácil de obtener ingresos!"
        ],
        "TradeNow": "Negocia ahora",
        "Box6Title1": "Inicie transacciones en cualquier momento y en cualquier lugar",
        "Box6Title2": "Tanto la APP como la WEB pueden abrir transacciones rápidamente",
        "FooterItems1": [
            "Términos y Políticas",
            "Tasa estándar",
            "Advertencia de riesgo",
            "Anti lavado de dinero",
            "Sobre nosotros"
        ],
        "FooterItems2": [
            "Privacidad del servicio",
            "Términos de servicio",
            "Declaracion de privacidad",
            "Papel blanco",
            "certificación MSB"
        ],
        "FooterItems3": [
            "Centro de ayuda",
            "Guía para principiantes",
            "Manual de operaciones",
            "Contáctenos"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "Ingrese un nombre de moneda",
        "Currency": "Divisa",
        "LatestPrice": "Último precio",
        "Change": "Cambiar",
        "Highest": "24H más alto",
        "Lowest": "24H más bajo",
        "OrderBook": "Libro de pedidos",
        "LatestRecord": "Último registro",
        "All": "Todo",
        "Bids": "Ofertas",
        "Asks": "pregunta",
        "Price": "Precio",
        "Quantity": "Cantidad",
        "Total": "Total",
        "Time": "Tiempo",
        "AveragePrice": "Precio promedio",
        "EnterPayPass": "Por favor ingrese la contraseña de la transacción",
        "BuyTips": "¿Estás seguro de que quieres comprar?",
        "type": "Tipo"
    },
    "PerpetualContract": {
        "OrderType": "Tipo de orden",
        "Market": "Mercado",
        "EnterQuantity": "Ingrese la cantidad de compra",
        "HandlingFee": "Tasa de tramitación",
        "AvailableBalance": "Saldo disponible",
        "Lever": "Palanca",
        "Fee": "Tarifa",
        "OpenLong": "Abierto largo",
        "OpenShort": "abierto corto",
        "CurrentOrders": "Orden actual",
        "OrderHistory": "Historial de pedidos",
        "Margin": "Margen",
        "CurrentPrice": "Precio actual",
        "OpeningPrice": "Precio de apertura",
        "UnsettledProfit": "Beneficio no liquidado",
        "Operate": "Funcionar",
        "OpenTime": "tiempo abierto",
        "ClosingTime": "hora de cierre",
        "ClosingPrice": "Precio de cierre",
        "SettlementProfit": "Beneficio de liquidación",
        "ClosePosition": "Cerrar posición",
        "Edit": "Editar",
        "EditOrder": "Editar orden",
        "EnterCloseQuantity": "Por favor ingrese la cantidad",
        "ConfirmOrder": "Confirmar pedido",
        "TakeProfitPrice": "Tomar precio de beneficio",
        "StopPrice": "Precio de parada"
    },
    "CurrencyTrading": {
        "Buy": "Comprar",
        "Sell": "Vender",
        "MyBalance": "Mi balance",
        "AvailablePurchaseAmount": "Cantidad disponible para la compra",
        "ConvertibleAmount": "Importe convertible",
        "CurrentPrice": "Precio actual",
        "BuyQuantity": "Comprar cantidad",
        "EnterBuyQuantity": "Introduce la cantidad a comprar",
        "SellQuantity": "Cantidad de venta",
        "EnterSellQuantity": "Introduce la cantidad a vender",
        "RequiredAmount": "Cantidad requerida",
        "ExchangeAmount": "Cantidad de cambio",
        "OrderHistory": "Historial de pedidos",
        "Status": "Estado",
        "Success": "Éxito"
    },
    "SecondContract": {
        "Buy": "Comprar",
        "OpeningQuantity": "Cantidad de apertura",
        "EnterOpeningQuantity": "Ingrese el monto de la moneda",
        "SelectQuantity": "Selecciona la cantidad",
        "OpeningTime": "Hora de apertura",
        "minute": "minuto",
        "Balance": "Balance",
        "Transfer": "Transferir",
        "HandlingFee": "Tasa de tramitación",
        "Profitability": "Rentabilidad",
        "BuyUp": "Comprar",
        "BuyDown": "comprar abajo",
        "InTransaction": "en transacción",
        "Closed": "Cerrado",
        "OrderNumber": "Número de orden",
        "CurrentPrice": "Precio actual",
        "Direction": "Dirección",
        "EstimatedProfitLoss": "Ganancias y pérdidas estimadas",
        "Countdown": "cuenta regresiva",
        "OpeningPrice": "Precio de apertura",
        "ClosingPrice": "Precio de cierre",
        "ProfitAndLoss": "Ganancia y perdida"
    },
    "Finance": {
        "TopTitle": "Tesoro financiero",
        "TopDesc": "Es un activo de cadena de bloques que contiene moneda para ganar intereses, una plataforma de inversión de ingresos estables, que incluye gestión financiera fija (bloqueada), gestión financiera actual y otros tipos de productos, que proporciona el mejor canal de inversión para usuarios de inversión estable.",
        "AllProducts": "Todos los productos",
        "InProgress": "En curso",
        "AboutToStart": "A punto de comenzar",
        "ItsOver": "Se acabó",
        "FinancialRecords": "Registros financieros",
        "ReferenceAnnualized": "Referencia anualizada",
        "day": "día",
        "hour": "hora",
        "minute": "minuto",
        "second": "segundo",
        "Unlock": "desbloquear",
        "RemainingPosition": "Posición restante",
        "LockedPositions": "Posiciones bloqueadas",
        "EndOfDistance": "fin de la distancia",
        "JoinNow": "Únete ahora",
        "OurAdvantage": "Nuestra ventaja",
        "AdvanListTitles": [
            "Renta eficiente",
            "Ciclo más corto",
            "Seguro y confiable"
        ],
        "AdvanListDescs": [
            "Use una variedad de combinaciones de herramientas financieras profesionales para crear productos financieros de alta calidad y alto rendimiento para los usuarios",
            "El ciclo es más flexible y el bloqueo es tan corto como 7 días.",
            "Las soluciones profesionales de gestión y control de riesgos brindan protección para el rendimiento de los activos"
        ],
        "JoinNum": "{joinNum} personas se han unido",
        "DailyRate": "Tarifa diaria",
        "StartingTime": "Tiempo de empezar",
        "LockPeriod": "Período de bloqueo",
        "RebateMethod": "método de reembolso",
        "RebateMethod1": "Rendimiento del principal al vencimiento",
        "RebateMethod2": "Reembolso diario",
        "LockPosition": "Posición de bloqueo",
        "ProductSpecification": "Especificaciones del producto",
        "LockedCurrency": "Moneda bloqueada",
        "SubscriptionAmount": "monto de la suscripción",
        "MinAndMax": "Mínimo/máximo",
        "TotalPosition": "posición total",
        "TradingRules": "Reglas comerciales",
        "BuyProductTips": "¿Está seguro de que desea suscribirse a este producto?",
        "Pending": "Pendiente",
        "Completed": "Terminado",
        "ProductName": "Nombre del producto",
        "PurchaseAmount": "Monto de la compra",
        "CumulativeIncome": "Ingreso acumulado",
        "EndTime": "Hora de finalización"
    },
    "Assets": {
        "FinancialRecords": "Registros financieros",
        "Deposit": "Depósito",
        "Withdraw": "Retirar",
        "Transfer": "Transferir",
        "TotalBalance": "Balance total",
        "Refresh": "Actualizar",
        "Currency": "Divisa",
        "AvailableBalance": "Saldo disponible",
        "Frozen": "Congelado",
        "Operate": "Funcionar",
        "Type": "Tipo",
        "Time": "Tiempo",
        "Quantity": "Cantidad",
        "SelectCurrency": "Seleccione el tipo de moneda",
        "Address": "DIRECCIÓN",
        "Notice": "Aviso",
        "DepositRecord": "Registro de depósito",
        "OrderNumber": "Número de orden",
        "Status": "Estado",
        "Status1": "En curso",
        "Status2": "Terminado",
        "Status3": "Fallar",
        "Status4": "Anormal",
        "Status5": "Cancelado",
        "WithdrawalAmount": "Cantidad de retiro",
        "Least": "El menos",
        "All": "Todo",
        "WithdrawalCurrency": "Moneda de retiro",
        "WithdrawalAddress": "dirección de retiro",
        "HandlingFee": "Tasa de tramitación",
        "ReceiptAmount": "Importe del recibo",
        "TransactionPassword": "Clave de transacción",
        "SetTransactionPassword": "Establecer contraseña de transacción",
        "GoogleVerificationCode": "Código de verificación de Google",
        "EnterWithdrawalAmount": "Por favor ingrese el monto del retiro",
        "EnterWithdrawalAddress": "Por favor ingrese la dirección de retiro",
        "EnterTransactionPassword": "Por favor ingrese la contraseña de la transacción",
        "EnterGoogleVerificationCode": "Por favor ingrese el código de verificación de Google",
        "WithdrawalsRecord": "registro de retiros",
        "ExchangeCurrency": "Cambio de divisas",
        "ExchangeAmount": "Cantidad de cambio",
        "ExchangeRate": "Tipo de cambio",
        "Expected": "Esperado",
        "TransferRecord": "Registro de transferencia",
        "CurrencyTransfer": "Moneda a transferir",
        "Success": "Éxito",
        "EnterExchangeAmount": "Por favor ingrese el monto del cambio"
    },
    "Account": {
        "Logout": "Cerrar sesión",
        "PersonalCenter": "centro personal",
        "LoginHistory": "historial de inicio de sesión",
        "Time": "Tiempo",
        "Account": "Cuenta",
        "LoginPassword": "Contraseña de inicio de sesión",
        "Modify": "Modificar",
        "TransactionPassword": "Clave de transacción",
        "SetTransactionPassword": "Establecer contraseña de transacción",
        "ResetTransactionPassword": "Restablecer contraseña de transacción",
        "Recommend": "Recomendar",
        "MobileAuth": "Autenticación móvil",
        "BindMailbox": "Vincular buzón",
        "GoogleAuth": "Autenticación de Google",
        "KYCCertification": "Certificación KYC",
        "MobileAuthDesc": "Se utiliza para recuperar contraseñas y modificar la configuración de seguridad para la verificación de seguridad",
        "BindMailboxDesc": "Se utiliza para recuperar contraseñas y modificar la configuración de seguridad para la verificación de seguridad",
        "GoogleAuthDesc": "Se utiliza para retirar moneda para verificación de seguridad",
        "Add": "Agregar",
        "Remove": "Eliminar",
        "GoAuth": "Ir a autenticación",
        "Verified": "Verificado",
        "UnderReview": "Bajo revisión",
        "ChangeLoginPassword": "Cambiar contraseña de inicio de sesión",
        "ChangeLoginPasswordTips": "No puede retirar efectivo dentro de las 24 horas posteriores al cambio de la contraseña de inicio de sesión, opere con precaución",
        "OriginalPassword": "Ingrese la contraseña original",
        "NewPassword": "Introduzca una nueva contraseña",
        "ConfirmPassword": "Introduce la nueva contraseña de nuevo",
        "EnterOriginalPassword": "Por favor ingrese la contraseña original",
        "EnterNewPassword": "Por favor ingrese una nueva contraseña",
        "EnterConfirmPassword": "Por favor, introduzca la nueva contraseña de nuevo",
        "EnterPasswordError": "El formato de la contraseña es de 6 a 20 caracteres",
        "EnterConfirmPasswordError": "Las contraseñas ingresadas dos veces son inconsistentes",
        "ChangeTransactionPasswordTips": "No puede retirar efectivo dentro de las 24 horas posteriores al cambio de la contraseña de la transacción, opere con precaución",
        "EnterLoginPassword": "Ingrese la contraseña de inicio de sesión",
        "PleaseEnterLoginPassword": "Por favor, introduzca su contraseña",
        "AddGoogleAuth": "Agregar Autenticador de Google",
        "Step1": "Paso 1 Descarga la aplicación 'Google Authenticator'",
        "Step2": "Paso 2 Agregue la clave en Google Authenticator y haga una copia de seguridad",
        "Step3": "Paso 3 Ingrese el código de verificación de 6 dígitos de Google Validator",
        "Step1Desc": "Los usuarios de iOS inician sesión en la App Store, buscan 'Autenticador' para descargar<br/><br/> Los usuarios de Android inician sesión en la tienda de aplicaciones o usan el navegador móvil para buscar 'Google Authenticator' para descargar",
        "Step2Desc": "Abra Google Authenticator, escanee el código QR a continuación o ingrese manualmente la clave a continuación para agregar un token de verificación",
        "CopyKey": "Copiar clave",
        "EnterGoogleVerificationCode": "Ingrese el código de verificación de Google",
        "PleaseEnterGoogleVerificationCode": "Por favor ingrese el código de verificación de Google",
        "RemoveGoogleAuth": "Eliminar el validador de Google",
        "RemoveGoogleAuthTips": "No puede retirar efectivo dentro de las 24 horas posteriores a la eliminación de Google Validator, opere con precaución",
        "UploadIDPhoto": "Subir foto de identificación",
        "UploadIDPhotoTips": "Sube una imagen a color de todo el documento. No se permiten capturas de pantalla. El tamaño de la imagen no puede exceder los 10M y solo puede estar en formato JPG o PNG.",
        "FrontPhoto": "foto del frente de la tarjeta de identificación",
        "BackPhoto": "Foto del reverso de la tarjeta de identificación",
        "FrontPhotoTips": "Haga clic para cargar la foto del frente de la tarjeta de identificación",
        "BackPhotoTips": "Haga clic para cargar una foto del reverso de su tarjeta de identificación"
    },
    "FundingType": {
        "All": "Todo",
        "system": "Operación del sistema",
        "commission": "Comisión",
        "recharge": "Recargar",
        "withdraw": "Retirar",
        "withdrawFail": "Retiro fallido",
        "second_order": "Orden de contrato de opción",
        "secondOrderWin": "Ganancia del contrato de opciones",
        "perpetual_order": "Depósito de pedido de futuros perpetuos + tarifa de manejo",
        "perpeTualOrderClo": "Cierre de futuros perpetuos",
        "trade": "Transacción de moneda",
        "buyFinance": "comprar financiación",
        "financeProfit": "Reembolso financiero",
        "financeMoney": "Finanzas de vuelta principal"
    },
    "Common": {
        "Language": "Idioma",
        "Tips": "Consejos",
        "Confirm": "Confirmar",
        "Cancel": "Cancelar",
        "Submit": "Entregar",
        "copy": "Copiar",
        "CopySuccessfully": "Copiar con éxito",
		"Previous": "Previo",
		"Next": "Próximo",
        "NoData": "Sin datos",
        "Loading": "Espera...",
        "Back": "Atrás",
        "Details": "Detalles"
    }
}
